import React from 'react';
import { PathService } from '../../services';
import { ServiceViewContext, TemplateProps } from '../../types';
import { ServicesView } from '../../views';

const Service: React.FunctionComponent<TemplateProps<ServiceViewContext>> = ({
  location,
  pageContext,
}) => {
  PathService.set(location.pathname);
  return (
    <ServicesView
      content={JSON.parse(pageContext.content)}
      page={JSON.parse(pageContext.page)}
    />
  );
};

export default Service;
